<template>
  <v-container grid-list-s class="pt-4">
    <v-row>
      <v-col md="8" offset="2" align="start">
        <h2 class="display-2 font-weight-bold my-md-2 primary--text">
          {{ $t('Registration') }}
        </h2>
        <hr />
        <h3 class="text-h6">
          {{ $t('personalInformation') }}
        </h3>
        <br />
        <PersonalRegistrationForm
          ref="userRegistrationFormRef"
          :form-with-password="true"
          :passport-uploader-details="$t('passportUploaderDetailsSelf')"
          :passport-uploader-placeholder="$t('passportUploaderPlaceholderSelf')"
          :passport-uploader-title="$t('passport')"
          :user="registerObject"
          :countries="countries"
          :errors="userErrors"
        />
        <hr class="my-md-3" />
        <v-row>
          <div class="text-h6 col-10">
            {{ $t('trustedPersonFormTogglerText') }}
          </div>
          <v-btn
            class="offset-1"
            fab
            dark
            color="primary"
            @click="showTrustedPersonForm = !showTrustedPersonForm"
          >
            <v-icon dark>
              {{ showTrustedPersonForm ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
          </v-btn>
        </v-row>
        <br />
        <PersonalRegistrationForm
          v-if="showTrustedPersonForm"
          ref="trustedPersonRegistrationFormRef"
          :passport-uploader-details="$t('passportUploaderDetailsOther')"
          :passport-uploader-placeholder="$t('passportPlaceholderOther')"
          :passport-uploader-title="$t('passport')"
          :user="trustedPersonObject"
          :countries="countries"
          :errors="trustedPersonErrors"
        />

        <hr class="my-md-3" />
        <v-row justify="end">
          <span class="col-5 text-caption px-0">
            {{ $t('registrationNote') }}
          </span>
          <br />
        </v-row>

        <v-row>
          <br />
          <v-btn
            color="primary"
            large
            type="submit"
            class="text-capitalize col-3 offset-9"
            @click.prevent="registerUser"
          >
            {{ $t('register') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import PersonalRegistrationForm from '@/components/PersonalRegistrationForm.vue'

export default Vue.extend({
  name: 'Register',
  components: {
    PersonalRegistrationForm
  },
  data: () => ({
    registerObject: {
      lastName: '',
      firstName: '',
      nationalities: '',
      birthDate: '',
      birthPlace: '',
      addressStreet: '',
      postalCode: '',
      city: '',
      country: '',
      phoneCode: '',
      phoneNumber: '',
      email: '',
      password: '',
      passport: null
    },
    trustedPersonObject: {
      lastName: '',
      firstName: '',
      nationalities: '',
      birthDate: '',
      birthPlace: '',
      addressStreet: '',
      postalCode: '',
      city: '',
      country: '',
      phoneCode: '',
      phoneNumber: '',
      email: '',
      password: '',
      passport: null
    },
    showTrustedPersonForm: false,
    userErrors: {},
    trustedPersonErrors: {}
  }),
  computed: {
    ...mapState({
      countries: state => state.countries.countries,
      registrationStatus: state => state.auth.registrationStatus
    }),
    requestBody() {
      var formData = new FormData()

      formData.append('email', this.registerObject.email)
      formData.append('first_name', this.registerObject.firstName)
      formData.append('last_name', this.registerObject.lastName)
      formData.append('password', this.registerObject.password)
      formData.append('profile__birth_place', this.registerObject.birthPlace)
      formData.append('profile__phone_code', this.registerObject.phoneCode)
      formData.append('profile__phone_number', this.registerObject.phoneNumber)
      formData.append(
        'profile__nationalities',
        this.registerObject.nationalities.split(',')
      )
      formData.append('profile__passport', this.registerObject.passport)
      formData.append(
        'profile__address__street',
        this.registerObject.addressStreet
      )
      formData.append(
        'profile__address__postal_code',
        this.registerObject.postalCode
      )
      formData.append('profile__address__city', this.registerObject.city)
      formData.append('profile__address__country', this.registerObject.country)
      formData.append('profile__birth_date', this.registerObject.birthDate)

      if (this.showTrustedPersonForm) {
        formData.append('trusted_person__email', this.trustedPersonObject.email)
        formData.append(
          'trusted_person__first_name',
          this.trustedPersonObject.firstName
        )
        formData.append(
          'trusted_person__last_name',
          this.trustedPersonObject.lastName
        )
        formData.append(
          'trusted_person__birth_place',
          this.trustedPersonObject.password
        )
        formData.append(
          'trusted_person__birth_place',
          this.trustedPersonObject.birthPlace
        )
        formData.append(
          'trusted_person__phone_code',
          this.trustedPersonObject.phoneCode
        )
        formData.append(
          'trusted_person__phone_number',
          this.trustedPersonObject.phoneNumber
        )
        formData.append(
          'trusted_person__nationalities',
          this.trustedPersonObject.nationalities.split(',')
        )
        formData.append(
          'trusted_person__passport',
          this.trustedPersonObject.passport
        )
        formData.append(
          'trusted_person__address__street',
          this.trustedPersonObject.addressStreet
        )
        formData.append(
          'trusted_person__address__postal_code',
          this.trustedPersonObject.postalCode
        )
        formData.append(
          'trusted_person__address__city',
          this.trustedPersonObject.city
        )
        formData.append(
          'trusted_person__address__country',
          this.trustedPersonObject.country
        )
        formData.append(
          'trusted_person__birth_date',
          this.trustedPersonObject.birthDate
        )
      }
      return formData
    }
  },
  created() {
    this.getCountries()
  },
  methods: {
    ...mapActions(['addNotificationAlert', 'getCountries', 'register']),
    async registerUser() {
      const userFormValid = this.$refs.userRegistrationFormRef.validate()
      let trustedPersonFormValid = true
      if (this.showTrustedPersonForm) {
        trustedPersonFormValid = this.$refs.trustedPersonRegistrationFormRef.validate()
      }
      if (!userFormValid || !trustedPersonFormValid) return

      await this.register(this.requestBody).then(res => {
        if (this.registrationStatus === 'success') {
          this.addNotificationAlert({
            type: 'success',
            text: this.$t('registrationSuccess')
          })
          setTimeout(
            async () => await this.$router.push({ name: 'Login' }),
            4000
          )
        } else if (this.registrationStatus === 'error') {
          this.addNotificationAlert({
            type: 'error',
            text: this.$t('registrationFailure')
          })
          this.userErrors = {}
          this.trustedPersonErrors = {}
          const trustedPersonPrefix = 'trusted_person__'
          const profilePrefix = 'profile__'
          for (let key in res.data)
            if (key.startsWith(trustedPersonPrefix))
              this.trustedPersonErrors[key.replace(trustedPersonPrefix, '')] =
                res.data[key][0]
            else
              this.userErrors[key.replace(profilePrefix, '')] = res.data[key][0]
        }
      })
    }
  }
})
</script>
